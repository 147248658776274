<template>
  <div id="events-create">
    <v-card>
      <v-card-title class="text-h5">
        <h2>Events Create</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Create a new National / Zone / LOM events.</h4></v-card-subtitle
      >

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>Regular subscription can be paid only once.</li>
            <li>
              After paying regular subscription, choose additional subcription.
            </li>
            <li>
              Late fee is optional but paying late fee is required to get voting
              rights.
            </li>
            <li>
              After completing the payment, kindly select your members for whom
              the payment was made.
            </li>
          </ol>
        </v-container>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p><span class="text-danger">*</span> indicates required field</p>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="backPage"
                color="primary"
              >
                Back
              </v-btn>
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#a4c639"
              >
                Refresh
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="addFacultyRecordPrompt" persistent max-width="75%">
            <add-event-faculty
              pageTitle="Add event faculty"
              pageDescription="Add event faculty"
              :addFacultyRecordPrompt="addFacultyRecordPrompt"
              @hideAddFacultyRecordPrompt="addNewFaculty($event)"
              v-if="addFacultyRecordPrompt"
            ></add-event-faculty>
          </v-dialog>
          <v-dialog
            v-model="addDocumentRecordPrompt"
            persistent
            max-width="75%"
          >
            <add-event-document
              pageTitle="Add event document"
              pageDescription="Add event document"
              :addDocumentRecordPrompt="addDocumentRecordPrompt"
              @hideAddDocumentRecordPrompt="addNewDocument($event)"
              v-if="addDocumentRecordPrompt"
            ></add-event-document>
          </v-dialog>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Event Type</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="EventType"
                  :rules="EventTypeRules"
                  :items="EventTypeOptions"
                  :loading="EventTypeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Portfolio</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="PortfolioId"
                  :rules="PortfolioIdRules"
                  :items="PortfolioIdOptions"
                  :loading="PortfolioIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> 100% Efficiency</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="EfficiencyId"
                  :rules="EfficiencyIdRules"
                  :items="EfficiencyIdOptions"
                  :loading="EfficiencyIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Event Category</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="EventCategoryId"
                  :rules="EventCategoryIdRules"
                  :items="EventCategoryIdOptions"
                  :loading="EventCategoryIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>Zone <small> (optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="ZoneCode"
                  :rules="ZoneCodeRules"
                  :items="ZoneCodeOptions"
                  :loading="ZoneCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>LOM <small> (optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="LomCode"
                  :rules="LomCodeRules"
                  :items="LomCodeOptions"
                  :loading="LomCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="6" md="6">
                <label>
                  <h6>Event Name <small> (optional)</small></h6>
                </label>
                <v-text-field
                  v-model="EventName"
                  :rules="EventNameRules"
                  hint="Enter the event name"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="12" md="12">
                <label>
                  <h6>Event description <small> (optional)</small></h6>
                </label>
                <v-text-field
                  v-model="EventDescription"
                  :rules="EventDescriptionRules"
                  hint="Enter the event description"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Membership Id for event chairman <small> (optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="ChairmanMembershipId"
                  :rules="ChairmanMembershipIdRules"
                  hint="Membership id for event chairman"
                  append-outer-icon="mdi-magnify-plus"
                  :loading="ChairmanMembershipIdLoadingFlag"
                  @click:append-outer="getMemberDetails(1)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>Event chairman details</h6>
                </label>
                <br />
                <img
                  :src="ChairmanData.MemberImage"
                  width="100"
                  v-if="ChairmanData.Fullname != ''"
                />
                <h5>{{ ChairmanData.Fullname }}</h5>
                <h6>{{ ChairmanData.MemberDesignation }}</h6>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Membership Id for event director <small> (optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="DirectorMembershipId"
                  :rules="DirectorMembershipIdRules"
                  hint="Membership id for event director"
                  append-outer-icon="mdi-magnify-plus"
                  :loading="DirectorMembershipIdLoadingFlag"
                  @click:append-outer="getMemberDetails(2)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>Event director details</h6>
                </label>
                <br />
                <img
                  :src="DirectorData.MemberImage"
                  width="100"
                  v-if="DirectorData.Fullname != ''"
                />
                <h5>{{ DirectorData.Fullname }}</h5>
                <h6>{{ DirectorData.MemberDesignation }}</h6>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>From Date <small> (optional)</small></h6>
                <v-dialog
                  ref="dialog1"
                  v-model="DateModal1"
                  :return-value.sync="FromDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="FromDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="DateModal1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog1.save(FromDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Start Time <small> (optional)</small></h6>
                <v-dialog
                  ref="dialog2"
                  v-model="TimeModal1"
                  :return-value.sync="StartTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="StartTime"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="TimeModal1"
                    v-model="StartTime"
                    scrollable
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="TimeModal1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog2.save(StartTime)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>To Date <small> (optional)</small></h6>
                <v-dialog
                  ref="dialog3"
                  v-model="DateModal2"
                  :return-value.sync="ToDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="ToDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="DateModal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog3.save(ToDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>End Time <small> (optional)</small></h6>
                <v-dialog
                  ref="dialog4"
                  v-model="TimeModal2"
                  :return-value.sync="EndTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="EndTime"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="TimeModal2"
                    v-model="EndTime"
                    scrollable
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="TimeModal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog4.save(EndTime)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" lg="12" md="12">
                <label>
                  <h6>Venue details <small> (optional)</small></h6>
                </label>
                <v-text-field
                  v-model="VenueDetails"
                  :rules="VenueDetailsRules"
                  hint="Enter the venue details"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="12" md="12">
                <br />
                <br />
                <hr />
                <h4>
                  Click <v-icon color="blue">mdi-plus-circle</v-icon> icon to
                  add event faculty
                </h4>
                <v-data-table
                  :headers="tableColumns1"
                  :items="AllFaculty"
                  hide-default-footer
                  disable-sort
                  id="faculty-table"
                >
                  <template v-slot:no-data>
                    <v-btn
                      icon
                      color="blue"
                      @click.prevent="addFacultyRecordPrompt = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.ImageUrl="{ item }">
                    <v-img
                      :src="item.ImageUrl"
                      width="100"
                      height="100"
                      v-if="item.ImageUrl != ''"
                    />
                  </template>
                  <template v-slot:item.action="{ item, index }">
                    <v-btn
                      icon
                      color="blue"
                      @click.prevent="addFacultyRecordPrompt = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click="deleteRow(1, index, item)">
                      <v-icon>mdi-delete-circle-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              <!-- <v-col cols="12" sm="12" lg="12" md="12">
                <hr />
                <v-simple-table id="faculty-table">
                  <template>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <h2>Faculty type</h2>
                        </th>
                        <th class="text-center">
                          <h2>Member type</h2>
                        </th>
                        <th class="text-center">
                          <h2>Membership Id</h2>
                        </th>
                        <th class="text-center">
                          <h2>Faculty name</h2>
                        </th>
                        <th class="text-center">
                          <h2>Member details</h2>
                        </th>
                        <th class="text-center">
                          <h2>Faculty photo</h2>
                        </th>
                        <th>
                          <v-btn icon color="blue" @click="addNewRow(1)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tr, k) in AllFaculty" :key="k">
                        <td>
                          <v-select
                            :reduce="(option) => option.value"
                            v-model="tr.FacultyTypeId"
                            :items="FacultyTypeIdOptions"
                            :rules="FacultyTypeIdRules"
                            dense
                            required
                            outlined
                          />
                        </td>
                        <td>
                          <v-select
                            :reduce="(option) => option.value"
                            v-model="tr.MemberTypeId"
                            :items="MemberTypeIdOptions"
                            :rules="MemberTypeIdRules"
                            dense
                            required
                            outlined
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-model="tr.MembershipId"
                            :rules="MembershipIdRules"
                            dense
                            required
                            outlined
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="tr.FacultyName"
                            :rules="FacultyNameRules"
                            dense
                            required
                            outlined
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="tr.FacultyDetails"
                            :rules="FacultyDetailsRules"
                            dense
                            required
                            outlined
                          ></v-text-field>
                        </td>
                        <td>
                          <input
                            type="file"
                            @change="addFacultyImages($event, k)"
                            accept="image/*"
                          />
                        </td>
                        <td>
                          <v-btn icon color="blue" @click="addNewRow(1)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="red"
                            @click="deleteRow(1, k, item)"
                          >
                            <v-icon>mdi-delete-circle-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col> -->
              <v-col cols="12" sm="12" lg="12" md="12">
                <br />
                <br />
                <hr />
                <h4>
                  Click <v-icon color="blue">mdi-plus-circle</v-icon> icon to
                  add event images
                </h4>
                <v-data-table
                  :headers="tableColumns2"
                  :items="AllDocuments"
                  hide-default-footer
                  disable-sort
                  id="document-table"
                >
                  <template v-slot:no-data>
                    <v-btn
                      icon
                      color="blue"
                      @click.prevent="addDocumentRecordPrompt = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.ImageUrl="{ item }">
                    <v-img
                      :src="item.ImageUrl"
                      width="100"
                      height="100"
                      v-if="item.ImageUrl != ''"
                    />
                  </template>
                  <template v-slot:item.action="{ item, index }">
                    <v-btn
                      icon
                      color="blue"
                      @click.prevent="addDocumentRecordPrompt = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click="deleteRow(2, index, item)">
                      <v-icon>mdi-delete-circle-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  type="submit"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#ff7f50"
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                  Submit
                </v-btn>
                <br />
                <upload-progress
                  :ProgessStart="progessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-container class="py-0" v-if="SearchFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import AddEventDocument from "@/view/pages/erp/events/AddEventDocument.vue";
import AddEventFaculty from "@/view/pages/erp/events/AddEventFaculty.vue";

export default {
  mixins: [common],
  components: {
    AddEventDocument,
    AddEventFaculty,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      SearchResultFlag: false,
      ShowFormFlag: false,
      NewTitleFlag: true,
      NotesFlag: false,
      rows: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      TabFlag3: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      progessStart: 0,
      uploadPercentage: 0,

      JciYearCode: "",

      EventTypeRules: [(v) => !!v || "Event type is required"],
      EventType: 1,
      EventTypeOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "National Events" },
        { value: 2, text: "Zone Events" },
        { value: 3, text: "LOM Events" },
      ],
      EventTypeOptionsLoading: false,

      PortfolioIdRules: [(v) => !!v || "Portfolio is required"],
      PortfolioId: 1,
      PortfolioIdOptions: [],
      PortfolioIdOptionsLoading: false,

      EfficiencyIdRules: [(v) => !!v || "100% Efficiency criteria is required"],
      EfficiencyId: 1,
      EfficiencyIdOptions: [],
      EfficiencyIdOptionsLoading: false,

      EventCategoryIdRules: [(v) => !!v || "Event category is required"],
      EventCategoryId: 1,
      EventCategoryIdOptions: [],
      EventCategoryIdOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      EventName: "test",
      EventNameRules: [(v) => !!v || "Event name is required"],

      EventDescription: "",
      EventDescriptionRules: [],

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      DateModal1: false,

      StartTime: null,
      TimeModal1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      DateModal2: false,

      EndTime: null,
      TimeModal2: false,

      VenueDetails: "",
      VenueDetailsRules: [],

      ChairmanData: {},
      ChairmanMembershipId: "",
      ChairmanMembershipIdRules: [],
      ChairmanMembershipIdLoadingFlag: false,

      DirectorData: {},
      DirectorMembershipId: "",
      DirectorMembershipIdRules: [],
      DirectorMembershipIdLoadingFlag: false,

      EventDescription: "",
      EventDescriptionRules: [],

      AllFaculty: [],
      uploadedFacultyImages: [],
      tableColumns1: [
        { text: "Faculty Type", value: "FacultyTypeName" },
        { text: "Member Type", value: "MemberTypeName" },
        { text: "Membership Id", value: "MembershipId" },
        { text: "Faculty name", value: "FacultyName" },
        { text: "Faculty details", value: "FacultyDetails" },
        { text: "Image", value: "ImageUrl" },
        { text: "Action", value: "action" },
      ],

      Faculty: {},
      addFacultyRecordPrompt: false,

      AllDocuments: [],
      uploadedEventImages: [],
      tableColumns2: [
        { text: "Documnet Type", value: "DocumentTypeName" },
        { text: "Description", value: "DocumentDescription" },
        { text: "Image", value: "ImageUrl" },
        { text: "Action", value: "action" },
      ],

      Document: {},
      addDocumentRecordPrompt: false,

      FacultyTypeIdRules: [(v) => !!v || "Faculty type is required"],
      FacultyTypeIdOptions: [],

      MemberTypeIdRules: [(v) => !!v || "Member type is required"],
      MemberTypeIdOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "Member" },
        { value: 2, text: "Non Member" },
      ],

      MembershipIdRules: [],
      FacultyNameRules: [],
      FacultyDetailsRules: [],

      DocumentTypeIdRules: [(v) => !!v || "Document type is required"],
      DocumentTypeIdOptions: [],

      DocumentDescriptionRules: [],

      current_photo: null,
    };
  },
  computed: {},
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      // this.getRegionCodeOptions();
      this.LomCode = "";
      this.getLomCodeOptions();
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LoadingFlag = false;
      this.LomCodeOptionsLoading = false;
    },
    PortfolioIdOptions: function () {
      console.log("watch PortfolioIdOptions");
      this.LoadingFlag = false;
      this.PortfolioIdOptionsLoading = false;
    },
    EfficiencyIdOptions: function () {
      console.log("watch EfficiencyIdOptions");
      this.LoadingFlag = false;
      this.EfficiencyIdOptionsLoading = false;
    },
    EventCategoryIdOptions: function () {
      console.log("watch EventCategoryIdOptions");
      this.LoadingFlag = false;
      this.EventCategoryIdOptionsLoading = false;
    },
    ChairmanData: function () {
      console.log("watch ChairmanData");
      this.LoadingFlag = false;
      this.ChairmanMembershipIdLoadingFlag = false;
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.Member = {};
      this.MemberId = "";
      this.MembershipId = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    resetMember() {
      console.log("resetMember called");
      this.LomCode = "";
      this.Member = {
        MemberId: "",
        PrimaryMobile: "",
        EmailId: "",
      };
      this.MemberFlag = false;
    },
    resetSubscription() {
      this.SearchResultFlag = false;
      this.rows = [];
      this.PenaltySubscription = [];
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getZoneCodeOptions();
      this.getPortfolioIdOptions();
      this.getEfficiencyIdOptions();
      this.getEventCategoryIdOptions();
      // this.getFacultyTypeIdOptions();
      // this.getDocumentTypeIdOptions();
    },
    addFacultyImages(event, k) {
      console.log("addFacultyImages called");
      var n1 = this.uploadedFacultyImages.length;
      var file = event.target.files[0];
      console.log("k=" + k + ", event=" + JSON.stringify(file));
      this.uploadedFacultyImages[k] = file;
    },
    addEventImages(event, k) {
      console.log("addEventImages called");
      var n1 = this.uploadedEventImages.length;
      var file = event.target.files[0];
      console.log("k=" + k + ", event=" + JSON.stringify(file));
      this.uploadedEventImages[k] = file;
    },
    addNewFaculty(faculty) {
      console.log("addNewFaculty called");
      this.addFacultyRecordPrompt = false;
      console.log("faculty=" + JSON.stringify(faculty));
      this.Faculty = faculty;
      this.addNewRow(1);
    },
    addNewDocument(document) {
      console.log("addNewDocument called");
      this.addDocumentRecordPrompt = false;
      console.log("document=" + JSON.stringify(document));
      this.Document = document;
      this.addNewRow(2);
    },
    addNewRow(tableId) {
      console.log("addNewRow called");

      console.log("tableId=" + tableId);

      switch (tableId) {
        case 1:
          var n1 = this.AllFaculty.length;
          console.log("n1=" + n1);
          var FacultyFlag = this.Faculty.FacultyFlag;
          console.log("FacultyFlag=" + FacultyFlag);
          if (FacultyFlag) {
            this.AllFaculty.push(this.Faculty);
          }
          this.Faculty = {};
          break;

        case 2:
          var n2 = this.AllDocuments.length;
          console.log("n2=" + n2);
          var DocumentFlag = this.Document.DocumentFlag;
          console.log("DocumentFlag=" + DocumentFlag);
          if (DocumentFlag) {
            this.AllDocuments.push(this.Document);
          }
          this.Document = {};
          break;

        default:
          break;
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        switch (tableId) {
          case 1:
            this.AllFaculty.splice(index, 1);
            break;

          case 2:
            this.AllDocuments.splice(index, 1);
            break;

          default:
            break;
        }
      }
    },
    getPortfolioIdOptions() {
      console.log("getPortfolioIdOptions called");
      this.LoadingFlag = true;
      this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "PortfolioId";
      var selectbox1_destination = "PortfolioIdOptions";
      var selectbox1_url = "api/portfolio/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getEfficiencyIdOptions() {
      console.log("getEfficiencyIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.EfficiencyIdOptionsLoading = true;
        var selectbox1_source = "EfficiencyId";
        var selectbox1_destination = "EfficiencyIdOptions";
        var selectbox1_url = "api/year-wise-efficiency/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Year: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getEventCategoryIdOptions() {
      console.log("getEventCategoryIdOptions called");
      this.LoadingFlag = true;
      this.EventCategoryIdOptionsLoading = true;
      var selectbox1_source = "EventCategoryId";
      var selectbox1_destination = "EventCategoryIdOptions";
      var selectbox1_url = "api/jci-event-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LoadingFlag = true;
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getFacultyTypeIdOptions() {
      console.log("getFacultyTypeIdOptions called");
      this.LoadingFlag = true;
      // this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "FacultyTypeId";
      var selectbox1_destination = "FacultyTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 17,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDocumentTypeIdOptions() {
      console.log("getDocumentTypeIdOptions called");
      this.LoadingFlag = true;
      // this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "DocumentTypeId";
      var selectbox1_destination = "DocumentTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 16,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails(id) {
      console.log("getMemberDetails is called");

      if (id != "") {
        var MembershipId = "";

        switch (id) {
          case 1:
            MembershipId = this.ChairmanMembershipId;
            this.ChairmanMembershipIdLoadingFlag = true;
            this.ChairmanData = {};
            break;
          case 2:
            MembershipId = this.DirectorMembershipId;
            this.DirectorMembershipIdLoadingFlag = true;
            this.DirectorData = {};
            break;

          default:
            break;
        }
        console.log("MembershipId=" + MembershipId);

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            switch (id) {
              case 1:
                thisIns.ChairmanMembershipIdLoadingFlag = false;
                thisIns.ChairmanData = records;
                break;
              case 2:
                thisIns.DirectorMembershipIdLoadingFlag = false;
                thisIns.DirectorData = records;
                break;

              default:
                break;
            }

            if (flag == 1) {
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ChairmanMembershipIdLoadingFlag = false;
            thisIns.DirectorMembershipIdLoadingFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        /*
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);
        */
        var htmlTxt = "";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var MemberTypeId1 = this.MemberTypeId;
        var JciMemberTypeId = this.JciMemberTypeId;
        var MemberTypeId = MemberTypeId1 == JciMemberTypeId ? 1 : MemberTypeId1;
        console.log(
          "MemberTypeId1=" +
            MemberTypeId1 +
            ", JciMemberTypeId=" +
            JciMemberTypeId +
            ", MemberTypeId=" +
            MemberTypeId
        );

        var EventData = {
          Year: this.JciYearCode,
          EventType: this.EventType,
          Portfolio: this.PortfolioId,
          EfficiencyCriteria: this.EfficiencyId,
          EventCategory: this.EventCategoryId,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          EventName: this.EventName,
          EventDescription: this.EventDescription,
          ChairmanMembershipId: this.ChairmanMembershipId,
          ChairmanData: this.ChairmanData,
          DirectorMembershipId: this.DirectorMembershipId,
          DirectorData: this.DirectorData,
          FromDate: this.FromDate,
          StartTime: this.StartTime,
          ToDate: this.ToDate,
          EndTime: this.EndTime,
          VenueDetails: this.VenueDetails,
          Faculty: this.AllFaculty,
          Documents: this.AllDocuments,
        };

        console.log({EventData});

        console.table(this.ChairmanData);
        console.table(this.DirectorData);

        let upload = new FormData();
        upload.append("Form", JSON.stringify(EventData));

        var n1 = this.AllFaculty.length;
        var m1 = 0;
        for (var i = 0; i < n1; i++) {
          var uploadedImageFlag = this.AllFaculty[i].FacultyFlag;
          console.log(
            "i=" + i + ", m1=" + m1 + ", uploadedImageFlag=" + uploadedImageFlag
          );
          if (uploadedImageFlag) {
            var uploadedImageId = "FacultyImages" + m1;
            var uploadedImageFile = this.AllFaculty[i].UploadedImage;
            console.log(
              "i=" +
                i +
                ", uploadedImageId=" +
                uploadedImageId +
                ", file=" +
                JSON.stringify(uploadedImageFile)
            );
            // var uploadedFile = this.$refs[refName].files[0]
            upload.append(uploadedImageId, uploadedImageFile);
            m1++;
          }
        }

        var n2 = this.AllDocuments.length;
        var m2 = 0;
        for (var j = 0; j < n2; j++) {
          var uploadedImageFlag = this.AllDocuments[j].DocumentFlag;
          console.log(
            "j=" + j + ", m2=" + m2 + ", uploadedImageFlag=" + uploadedImageFlag
          );
          if (uploadedImageFlag) {
            var uploadedImageId = "EventImages" + m2;
            var uploadedImageFile = this.AllDocuments[j].UploadedImage;
            console.log(
              "j=" +
                j +
                ", uploadedImageId=" +
                uploadedImageId +
                ", file=" +
                JSON.stringify(uploadedImageFile)
            );
            // var uploadedFile = this.$refs[refName].files[0]
            upload.append(uploadedImageId, uploadedImageFile);
            m2++;
          }
        }
        /*
        var upload = {
          member: member,
        };
        */
        console.log("upload=" + JSON.stringify(upload));

        this.progessStart = 1;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.sweetAlert("success", output, true);
              // thisIns.ShowFormFlag = false;
              // thisIns.resetForm();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.toast("error", message);
        this.sweetAlert("error", output, false);
      }
    },
  },
  beforeMount() {
    var CurrentJciYearId = this.$session.get("CurrentJciYearId");
    // CurrentJciYearId = 11;
    CurrentJciYearId =
      CurrentJciYearId == (null || undefined) ? "" : CurrentJciYearId;
    console.log("CurrentJciYearId=" + CurrentJciYearId);
    this.JciYearCode = CurrentJciYearId;
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#events-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #faculty-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 12px;
      color: red;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  #document-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 20px;
      color: skyblue;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>